



















import { Component, Emit, VModel, Vue } from 'vue-property-decorator'

@Component({
  name: 'SiteSelect'
})
export default class SiteSelect extends Vue {
  @VModel({ type: String }) siteName!: string

  get siteDisplayName() {
    switch (this.siteName) {
      case 'admin':
        return this.$t('page.site.port.admin')
      case 'agent':
        return this.$t('page.site.port.agent')
      case 'client':
        return this.$t('page.site.port.client')
    }
  }

  @Emit('change')
  clickSiteItem({ key }: { key: string }): string {
    this.siteName = key
    return key
  }
}
