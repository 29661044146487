

















import { Vue, Component } from 'vue-property-decorator'
import EntryLayoutToolbar from '../../pgEntry_layout_toolbar.vue'

@Component({
  name: 'PageEntryLayout',
  components: {
    EntryLayoutToolbar
  }
})
export default class PageEntryLayout extends Vue {}
